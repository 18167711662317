<template>
  <div>
    <h3>Agency Management</h3>
    <loading v-if="loading" />
    <div class="card mt-3" v-if="!loading">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <h4>Filter</h4>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Date From</label>
              <date-picker
                input-class="form-control"
                v-model="filter.from"
                placeholder="Date From"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Date To</label>
              <date-picker
                input-class="form-control"
                v-model="filter.to"
                placeholder="Date To"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Sort Order</label>
              <select v-model="filter.sort" class="form-control">
                <option value="latest">Latest</option>
                <option value="oldest">Oldest</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Status</label>
              <select v-model="filter.status" class="form-control">
                <option value="all">Show All</option>
                <option value="pending">Show Pending</option>
                <option value="approved">Show Approved</option>
                <option value="denied">Show Denied</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Search</label>
              <input
                type="text"
                class="form-control"
                placeholder="Search Name, Email, Phone Number"
                v-model="filter.term"
              />
            </div>
          </div>
          <div class="col-md-8 text-end">
            <button class="btn btn-primary" @click.prevent="search">
              <i class="lni lni-search me-2"></i>Filter
            </button>
          </div>
        </div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="agencies.length == 0">
              <td colspan="6">
                <div class="alert alert-warning">
                  There is no data to display
                </div>
              </td>
            </tr>
            <tr v-for="(user, i) in agencies" :key="`user-${i}`">
              <td>{{ i + 1 }}</td>
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.phone_number }}</td>
              <td>{{ user.status }}</td>
              <td>
                <router-link
                  :to="{ name: 'admin.agencies.show', params: { id: user.id } }"
                  class="btn btn-info btn-sm"
                  ><i class="lni lni-eye me-2"></i> Details</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-12">
            <pagination
              v-model="pagination.page"
              :records="pagination.records"
              @paginate="paginate"
              :perPage="10"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  mounted() {
    this.pagination.page = this.$route.query.page ? this.$route.query.page : 1;

    this.fetch();
  },

  data() {
    return {
      agencies: [],
      loading: true,
      pagination: { page: 1, records: 1 },
      filter: {
        sort: "latest",
        from: null,
        to: null,
        term: "",
        status: "all",
      },
    };
  },

  methods: {
    fetch() {
      this.loading = true;
      let url = `/api/v1/admin/agencies?search=${this.filter.term}&page=${this.pagination.page}&sort=${this.filter.sort}&status=${this.filter.status}`;

      if(this.filter.from) {
        url += `&date_from=${moment(this.filter.from).format('YYYY-MM-DD')}`
      }

      if(this.filter.to) {
        url += `&date_to=${moment(this.filter.to).format('YYYY-MM-DD')}`
      }

      this.axios
        .get(url)
        .then((response) => {
          this.agencies = response.data.agencies;
          this.pagination = response.data.pagination;
          this.loading = false;
        });
    },

    search() {
      this.$router.push({ query: { search: this.search_term } });
      this.fetch();
    },

    paginate() {
      this.$router.push({ query: { page: this.pagination.page } });
      this.fetch();
    },
  },
};
</script>
